<template>
  <a-layout-content :style="{ margin: '0px auto',  background: '#FFF', minHeight: '100%' }" class="content">
    <div class="aside">
      <ul style="list-style: none;">
        <li v-for="(item, index) in liArr" 
        :key="item.id"
        @click="selectIndex(index)"
        :class="selectedIndex == index ? 'routerItem-active':'routerItem'"
        >
          <div class="settingIcon"> 
            <bell-outlined v-if="item.icon=='bell'" />
            <message-outlined v-if="item.icon==='message'" />
            <heart-outlined v-if="item.icon=='heart'" />
          </div>
          <a @click="goOther(item)"><span class="routerTitle">{{item.title}}</span></a>
        </li>
      </ul>
    </div>
    <div class="mainContent">
      <router-view></router-view>
    </div>
  </a-layout-content>
</template>
<script>
  import ACC_Util from '../../utils/account.js';
  import { BellOutlined, MessageOutlined, HeartOutlined  } from '@ant-design/icons-vue';
  export default {
    name: 'Index',
    components: {
      BellOutlined,
      MessageOutlined,
      HeartOutlined
    },
    data() {
      return {
        liArr: [
          {
            id: '1',
            title: '系统消息',
            icon: 'bell',
            link: ''
          },
          {
            id: '2',
            title: '评论',
            icon: 'message',
            link: 'cmts',
          },
          {
            id: '3',
            title: '点赞和喜欢',
            icon: 'heart',
            link: 'likes'
          },
        ],
        selectedIndex: 0,
      }
    },
    methods: {
      selectIndex(index) {
        this.selectedIndex = index
      },
      goOther(item) {
        this.$router.push({
          path: `/msg/${ACC_Util.getUserId()}/${item.link}`
        })
      }
    },
  }
</script>

<style scoped>
  .content {
    width: 60%;
    text-align: center;
    display: flex;
  }
  .aside {
    width: 200px;
  }
  .routerItem {
    line-height: 42px;
    height: 42px;
    display: flex;
  }
  .routerItem-active {
    line-height: 42px;
    height: 42px;
    display: flex;
    background-color: #E8E8E8;
  }
  .settingIcon { 
    display: block;
    width: 32px;
    height: 32px;
  }
  .routerTitle {
    font-size: 15px;
    color:rgb(105, 105, 104)
  }
</style>